import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { options } from "../util";

export const RichTextRender = ({
  content,
}: {
  content: Parameters<typeof documentToReactComponents>[0];
}) => {
  return documentToReactComponents(content, options);
};
