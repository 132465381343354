import type { Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";

import { ContentfulImage } from "./components/contentful-image";

export const CONTENT_TYPES = {
  img: "image/jpeg",
  video: "video/mp4",
};

export const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { stockVideo, thumbnail } = node.data.target.fields;
      if (!stockVideo || !thumbnail) return null;
      return (
        <video
          src={stockVideo?.fields.file?.url}
          controls
          autoPlay={true}
          loop
          muted
          playsInline
          poster={thumbnail?.fields.file?.url}
        />
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { file, title } = node.data.target.fields;
      switch (file?.contentType) {
        case CONTENT_TYPES.img:
          return (
            <ContentfulImage alt={title} imageUrl={file?.url} maxWidth="full" />
          );
          break;
        case CONTENT_TYPES.video:
          return (
            <video
              src={file?.url}
              controls
              autoPlay={true}
              loop
              muted
              playsInline
            />
          );
          break;
        default:
          return null;
          break;
      }
    },
  },
} satisfies Options;
